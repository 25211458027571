import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { getChildrenToRender } from '../utils';
import { EdgeImg } from '../components/EdgeImg'

class Content15 extends React.PureComponent {
  render() {
    const { ...props } = this.props;
    const { dataSource, isMobile } = props;
    delete props.dataSource;
    delete props.isMobile;

    const childWrapper = dataSource.childWrapper

    return (
      <OverPack {...props} {...dataSource.OverPack} style={{ width: '100%'}}>
        <div {...dataSource.page}>
          <div className={'ant-row'}>
            <QueueAnim
              type="bottom"
              leaveReverse
              key="page"
              delay={[0, 100]}
              {...childWrapper}
            >
              {childWrapper.children.map(getChildrenToRender)}
            </QueueAnim>
          </div>
          <QueueAnim
          type="bottom"
          leaveReverse
          key="page"
          delay={[0, 100]}
          {...dataSource.titleWrapper}
          >
            {/* <div {...dataSource.titleWrapper}> */}
              {dataSource.titleWrapper.children.map(getChildrenToRender)}
              <EdgeImg style={{position: 'absolute', width: '23%', right: 0, top: '7%'}} name="gray_hives.png"></EdgeImg>
            {/* </div> */}
          </QueueAnim>
      </div>

      </OverPack>
    );
  }
}

export default Content15;
