import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getChildrenToRender } from '../utils';
import ClientRender from './ClientRender'
import { EdgeImg, EdgeBackgroundImg } from '../components/EdgeImg'

class Content410 extends React.PureComponent {
  getDelay = (e, b) => (e % b) * 100 + Math.floor(e / b) * 100 + b * 100;

  render() {
    const { ...props } = this.props;
    const { dataSource, isMobile } = props;
    delete props.dataSource;
    delete props.isMobile;
    let clearFloatNum = 0;
    const children = dataSource.block.children.map((item, i) => {
      const childObj = item.children;
      const delay = isMobile ? i * 50 : this.getDelay(i, 24 / item.md);
      const liAnim = {
        opacity: 0,
        type: 'from',
        ease: 'easeOutQuad',
        delay,
      };
      const childrenAnim = { ...liAnim, x: '+=10', delay: delay + 100 };
      clearFloatNum += item.md;
      clearFloatNum = clearFloatNum > 24 ? 0 : clearFloatNum;
      return (
        <TweenOne
          component={Col}
          animation={liAnim}
          key={item.name}
          {...item}
          componentProps={{ md: item.md, xs: item.xs }}
          className={
            !clearFloatNum
              ? `${item.className || ''} clear-both`.trim()
              : item.className
          }
        >
          {/* <TweenOne
            animation={{
              x: '-=10',
              opacity: 0,
              type: 'from',
              ease: 'easeOutQuad',
            }}
            key="img"
            {...childObj.icon}
          >
            <img src={childObj.icon.children} width="100%" alt="img" />
          </TweenOne> */}
          <div {...childObj.textWrapper}>
            <TweenOne
              key="h2"
              animation={childrenAnim}
              component="h2"
              {...childObj.title}
            >
              {childObj.title.children}
            </TweenOne>
            <TweenOne
              key="p"
              animation={{ ...childrenAnim, delay: delay + 200 }}
              component="div"
              {...childObj.content}
            >
              {childObj.content.children}
            </TweenOne>
          </div>
        </TweenOne>
      );
    });
    return (
      <ClientRender>
        <EdgeBackgroundImg {...props} {...dataSource.wrapper} name="about_lines_bg.png">
          <div className="mobile-hidden" style={{height: '100%', width: '10vw', position: 'absolute', margin: '-5vw -8vw', backgroundColor: '#FFC900'}}></div>
          <div {...dataSource.page}>
            <div {...dataSource.titleWrapper}>
              {dataSource.titleWrapper.children.map(getChildrenToRender)}
            </div>
            <OverPack {...dataSource.OverPack}>
                {
                  isMobile ? (
                    // <div style={{ width: '100%', padding: '0 16px', minHeight: '250px' }}>
                    //   <QueueAnim key="u" interval={800}  type="scale">
                    //     <div key="about_hive_1" style={{ width: '50%', margin: '24px 0' }}><EdgeImg name={'about_hive_1.png'} loading="eager"></EdgeImg></div>
                    //     <div key="about_hive_2" style={{ width: '50%', margin: '24px 0' }}><EdgeImg name={'about_hive_2.png'} loading="eager"></EdgeImg></div>
                    //     <div key="about_hive_3" style={{ width: '50%', margin: '24px 0' }}><EdgeImg name={'about_hive_3.png'} loading="eager"></EdgeImg></div>
                    //     <div key="about_hive_4" style={{ width: '50%', margin: '24px 0' }}><EdgeImg name={'about_hive_4.png'} loading="eager"></EdgeImg></div>
                    //     <div key="about_hive_5" style={{ width: '50%', margin: '24px 0' }}><EdgeImg name={'about_hive_5.png'} loading="eager"></EdgeImg></div>
                    //   </QueueAnim>
                    // </div>
                    <div style={{ minHeight: '250px'}}>
                      <QueueAnim key="u" interval={800}  type="alpha" style={{position: 'relative', height: 0, width: '100%', paddingBottom: '180%', marginBottom: '0'}}>
                        <div key="about_hive_1" style={{position: 'absolute', width: '54%', left: '0%', top: '0%'}}><EdgeImg name={'about_hive_1.png'} noBase64></EdgeImg></div>
                        <div key="about_hive_2" style={{position: 'absolute', width: '54%', right: 0, top: '15%'}}><EdgeImg name={'about_hive_2.png'} noBase64></EdgeImg></div>
                        <div key="about_hive_3" style={{position: 'absolute', width: '54%', left: '0%', top: '30%'}}><EdgeImg name={'about_hive_3.png'} noBase64></EdgeImg></div>
                        <div key="about_hive_4" style={{position: 'absolute', width: '54%', right: 0, top: '45%'}}><EdgeImg name={'about_hive_4.png'} noBase64></EdgeImg></div>
                        <div key="about_hive_5" style={{position: 'absolute', width: '54%', left: '0%', top: '60%'}}><EdgeImg name={'about_hive_5.png'} noBase64></EdgeImg></div>
                      </QueueAnim>
                    </div>
                  )
                  : (
                    <div style={{ minHeight: '250px'}}>
                      <QueueAnim key="u" interval={800}  type="alpha" style={{position: 'relative', height: 0, width: '100%', paddingBottom: '40%'}}>
                        <div key="about_hive_1" style={{position: 'absolute', width: '23%', left: '0', top: '18%'}}><EdgeImg name={'about_hive_1.png'} noBase64></EdgeImg></div>
                        <div key="about_hive_2" style={{position: 'absolute', width: '23%', left: '19%', top: '0'}}><EdgeImg name={'about_hive_2.png'} noBase64></EdgeImg></div>
                        <div key="about_hive_3" style={{position: 'absolute', width: '23%', left: '38%', top: '18%'}}><EdgeImg name={'about_hive_3.png'} noBase64></EdgeImg></div>
                        <div key="about_hive_4" style={{position: 'absolute', width: '23%', left: '57%', top: '0'}}><EdgeImg name={'about_hive_4.png'} noBase64></EdgeImg></div>
                        <div key="about_hive_5" style={{position: 'absolute', width: '23%', left: '76%', top: '18%'}}><EdgeImg name={'about_hive_5.png'} noBase64></EdgeImg></div>
                      </QueueAnim>
                    </div>
                  )
                }
                {/* <Row key="row" {...dataSource.block}>
                  {children}
                </Row> */}
            </OverPack>
          </div>
        </EdgeBackgroundImg>
      </ClientRender>
    );
  }
}

export default Content410;
