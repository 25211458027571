import React, { Fragment } from "react"
import { Link } from "gatsby"
import { BackTop } from 'antd';
import ClientMedia from "../components/ClientMedia"
import Media from "react-media"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */

import Nav0 from '../components/Nav0';
import Banner3 from '../components/Banner3';
import Content15 from '../components/Content15';
import Content151 from '../components/Content151';
import Content152 from '../components/Content152';
import Content410 from '../components/Content410';
import Contact0 from '../components/Contact0';
import Contact1 from '../components/Contact1';
import Footer0 from '../components/Footer0';

import {
  Nav00DataSource,
  Banner32DataSource,
  Content150DataSource,
  Content1510DataSource,
  Content1520DataSource,
  Content4100DataSource,
  Contact00DataSource,
  Contact10DataSource,
  Footer00DataSource,
} from '../data.source';
import '../less/antMotionStyle.less';
import { EdgeBackgroundImg } from "../components/EdgeImg"

export default class Works extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isClient: false,
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    // enquireScreen((b) => {
    //   this.setState({ isMobile: !!b });
    // });
    this.setState({ isClient: true })
  }

  handleMediaQueryChange = (matches) => {
    console.log(matches)
    // matches will be true or false based on the value for the media query
  }

  render() {
    // if (!this.state.isClient) return null
    // const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
    // if (typeof window !== `undefined`) {
    return (
      <Media queries={{
        mobile: "(max-width: 767px)",
        medium: "(max-width: 900px)",
        // small: "(max-width: 599px)",
        // medium: "(min-width: 600px) and (max-width: 1199px)",
        // large: "(min-width: 1200px)"
      }}>
        {matches => (
          // return (
          <div
            style={{margin: 'auto'}}
            className="templates-wrapper"
            ref={(d) => {
              this.dom = d;
            }}
          >
            <Fragment>
              <SEO></SEO>
              <Nav0
                id="Nav0_0"
                key="Nav0_0"
                dataSource={Nav00DataSource}
                isMobile={matches.mobile}
              />
              <Banner3
                id="Banner3_0"
                key="Banner3_0"
                dataSource={Banner32DataSource}
                isMobile={matches.mobile}
              />
              {
                matches.medium ? (
                  <div>
                    <Content15
                      id="Content15_0"
                      key="Content15_0"
                      dataSource={Content150DataSource}
                      isMobile={matches.medium}
                    />
                    <Content151
                      id="Content151_0"
                      key="Content151_0"
                      dataSource={Content1510DataSource}
                      isMobile={matches.medium}
                    />
                    <Content410
                      id="Content410_0"
                      key="Content410_0"
                      dataSource={Content4100DataSource}
                      isMobile={matches.medium}
                    />
                  </div>
                ) : (
                  // <EdgeBackgroundImg name="about_hive_big_bg.png">
                    <Content152
                      id="Content152_0"
                      key="Content152_0"
                      dataSource={Content1520DataSource}
                      dataSource2={Content4100DataSource}
                      isMobile={matches.mobile}
                    />
                  // </EdgeBackgroundImg>
                )
              }
              <Contact1
                id="Contact1_0"
                key="Contact1_0"
                dataSource={Contact10DataSource}
                isMobile={matches.mobile}
              />
              <Footer0
                id="Footer0_0"
                key="Footer0_0"
                dataSource={Footer00DataSource}
                isMobile={matches.mobile}
              />
              {
                matches.mobile && <BackTop />
              }
            </Fragment>
          </div>)}
        </Media>)
  }
}
