import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { getChildrenToRender } from '../utils';
import { EdgeImg, EdgeBackgroundImg } from '../components/EdgeImg'
import Content410 from '../components/Content410'

class Content152 extends React.PureComponent {
  render() {
    const { ...props } = this.props;
    const { dataSource, dataSource2, isMobile } = props;
    delete props.dataSource;
    delete props.isMobile;

    const childWrapper = isMobile ? dataSource.mobileChildWrapper : dataSource.childWrapper

    return (
      <EdgeBackgroundImg name="about_hive_big_bg.png" style={{width: '100%', height: '93vw', overflow: 'hidden'}} >

      {/* <EdgeBackgroundImg>position={'top'} bgSize={'cover'}> */}
      <OverPack {...props} {...dataSource.OverPack}>
        <div {...dataSource.page}>
        <QueueAnim
          type="bottom"
          leaveReverse
          key="page"
          delay={[0, 100]}
          {...dataSource.titleWrapper}
        >
          {dataSource.titleWrapper.children.map(getChildrenToRender)}
        </QueueAnim>
        <div className={'ant-row'}>
          <QueueAnim
            type="bottom"
            leaveReverse
            key="page"
            delay={[0, 100]}
            {...childWrapper}
          >
            {childWrapper.children.map(getChildrenToRender)}
          </QueueAnim>
          { isMobile &&
          (<div
            className={'ant-col-24'}
            style={{paddingTop: '16px'}}
          >
            <EdgeImg
              name={'about_hive_bg_mobile.png'}
              // style={{position: 'absolute', top: '40%', right: '-5%', width: '60%', height: 'auto', maxWidth: '460px', zIndex: 9}}
              // style={{padding: '32px'}}
            />
          </div>)}
        </div>
      </div>
        <Content410
          id="Content410_0"
          key="Content410_0"
          dataSource={dataSource2}
          isMobile={isMobile}
        />
      </OverPack>
      </EdgeBackgroundImg>
    );
  }
}

export default Content152;
